<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')" v-if="isAdmin">新增水源</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="waterList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="useTypeName" label="使用类别" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="buildTypeName" label="建成类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="isUse" label="是否可用" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success" size="size" v-show="scope.row.isUse===0">是</el-tag>
            <el-tag type="danger" size="size" v-show="scope.row.isUse===1">否</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="locationDescribe" label="位置描述" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="isAdmin">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="deleteWater(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看消防水源" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 水源弹窗 -->
    <el-dialog :title="optFlag?'添加水源':'修改水源'" class="dialog-content" :visible.sync="watershow" width="85%" @close="clear" top="1.3%" v-dialogDrag :close-on-click-modal="false">
      <div class="dialog">
        <div class="left">
          <el-form :model="water" ref="waterForm" :rules="rules" label-width="100">
            <el-form-item label="建成类型 " prop="buildType">
              <el-select v-model="water.buildType" placeholder="请选择" clearable size="small" style="float:left">
                <el-option v-for="(item,i) in buildTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否可用 " prop="isUse">
              <el-radio-group v-model="water.isUse">
                <el-radio v-for="(item,i) in [{id:0,label:'是'},{id:1,label:'否'}]" :key="i" :label="item.id">
                  {{item.label}}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="使用类别" prop="useType">
              <el-select v-model="water.useType" placeholder="请选择" clearable size="small" style="float:left">
                <el-option v-for="(item,i) in useTypeList" :key="i" :label="item.text" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经纬度">
              <el-row :gutter="20">
                <el-col :span="8" :offset="0">
                  <el-form-item prop="lng">
                    <el-input clearable v-model.number="water.lng" size="small" autocomplete="off" placeholder="经度" readonly></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="1" :offset="0">
                  #
                </el-col>
                <el-col :span="8" :offset="0">
                  <el-form-item prop="lat">
                    <el-input clearable v-model.number="water.lat" size="small" autocomplete="off" placeholder="纬度" readonly></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="位置描述 " prop="isUse">
              <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="water.locationDescribe">
              </el-input>
            </el-form-item>
            <el-form-item label="上传图片">
              <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
                <i class="el-icon-upload"></i>
                <div class="el-upload__text"><em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="right">
          <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="location" style="height:100%" :zoom="zoom" ak="ahVb0clSVLITXPpNieRI7DXL47tgHvcE" @ready="handler" @click="setPosition">
            <bm-view style="width: 100%; height:100%; flex: 1"></bm-view>
            <bm-local-search :keyword="addressKeyword" :auto-viewport="true" style="display: none"></bm-local-search>
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
            <bm-marker :position="location" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
            </bm-marker>
          </baidu-map>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="watershow = false">取 消</el-button>
        <el-button type="primary" @click="addWater" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateWater" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmView from 'vue-baidu-map/components/map/MapView.vue'
import BmLocalSearch from 'vue-baidu-map/components/search/LocalSearch.vue'
import BmGeolocation from 'vue-baidu-map/components/controls/Geolocation'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmGeolocation,
    BmMarker
  },
  data () {
    return {
      location: {
        lng: 0,
        lat: 0
      },
      zoom: 18,
      addressKeyword: '',
      enclosure: '',
      currentPage: 1,
      total: 0,
      waterList: [],
      useTypeList: [],
      buildTypeList: [],
      waterId: 0, // 单位ID
      water: {
        buildType: '',
        deviceProtect: '',
        imageUrl: '',
        isUse: 0,
        lat: 0,
        lng: 0,
        locationDescribe: '',
        useType: ''
      }, // 建筑表单
      waterFormCopy: {},
      rules: {},
      watershow: false,
      dialog2: false,
      optFlag: false,
      loading: false,
      enclosureShow: false,
      token: ''
    }
  },
  created () {
    this.initTable()
    this.getBuildType()
    this.getUseType()
    this.waterFormCopy = JSON.stringify(this.water)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    async handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    // 表格初始化
    async initTable () {
      const data = {}
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/waterManage/list', { params: data })
      if (result.code === 200) {
        this.loading = false
        this.waterList = result.data.result
        this.total = result.data.total
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    // 删除某条数据
    async deleteWater (id) {
      const confirm = await this.$confirm('此操作将永久删除该水源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/waterManage/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          const totalPage = Math.ceil((this.total - 1) / 10) // 总页数
          this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
          this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 修改某条数据
    async updateWater () {
      this.$refs.waterForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/waterManage/edit/${this.waterId}`, this.water)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.watershow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 新增
    async addWater () {
      this.$refs.waterForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/waterManage/add', this.water)
          if (result.code === 200) {
            this.$message.success('新增成功')
            const totalPage = Math.ceil((this.total + 1) / 10) // 总页数
            this.currentPage = this.currentPage > totalPage ? totalPage : this.currentPage
            this.currentPage = this.currentPage < 1 ? 1 : this.currentPage
            this.watershow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 设置位置
    async setPosition (e) {
      this.location.lng = e.point.lng
      this.location.lat = e.point.lat
      this.$set(this.water, 'lng', e.point.lng)
      this.$set(this.water, 'lat', e.point.lat)
    },
    // 地图初始化
    handler ({ BMap, map }) {
      const that = this
      var geolocation = new BMap.Geolocation()
      geolocation.getCurrentPosition(function (r) {
        that.location.lng = r.point.lng
        that.location.lat = r.point.lat
      })
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.watershow = true
          this.$nextTick(() => {
            this.$refs.waterForm.clearValidate()
          })
          this.optFlag = true
          break
        case 'edit':
          this.watershow = true
          this.optFlag = false
          this.$nextTick(() => {
            this.$refs.waterForm.clearValidate()
          })
          this.water = JSON.parse(JSON.stringify(row))
          this.waterId = row.id
          break
        case 'check':
          if (!row.imageUrl) {
            this.$message('该条数据暂无附件')
          } else {
            this.enclosure = row.imageUrl
            this.enclosureShow = true
          }
          break
      }
    },
    // 建成类型
    async getBuildType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'waterBuildType' } })
      if (result.code === 200) {
        this.buildTypeList = result.data
      }
    },
    // 使用类型
    async getUseType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'waterUseType' } })
      if (result.code === 200) {
        this.useTypeList = result.data
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.water.imageUrl = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    // 关闭弹窗清空表单
    clear () {
      this.water = JSON.parse(this.waterFormCopy)
      this.$refs.waterForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 150px;
  height: 150px;
}
.dialog-content {
  ::v-deep .el-dialog__body {
    padding-bottom: 0;
    .dialog {
      width: 100%;
      display: flex;
      .left {
        flex: 3;
        padding: 10px;
        box-sizing: border-box;
      }
      .right {
        flex: 7;
      }
    }
  }
}
</style>
